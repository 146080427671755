<template>
  <a-modal :class="$style.container" v-model="isShow" :title="(this.form.id ? '编辑' : '新增')" :width="500" @cancel="close" @ok="determine" :getContainer="_ => appRefs.app">
    <a-form-model ref="form" :class="$style.form__ct" :model="form" :rules="formRules" class="al-form" :label-col="{ span: 8 }" :wrapper-col="{ span: 12 }">
        <a-form-model-item label="机柜编号" prop="code">
            <a-input-number v-model="form.code" style="width:250px"/>
        </a-form-model-item>
        <a-form-model-item label="设备型号" prop="modelNo">
            <a-input v-model="form.modelNo"  placeholder="请输入"/>
        </a-form-model-item>
        <a-form-model-item label="签约类型" prop="contractTypeCode">
        <a-select v-model="form.contractTypeCode" style="width:250px">
          <a-select-option v-for="(item, index) in contractTypeList" :key="index" :value="item.code">{{ item.name }}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="驿站" prop="storeId">
          <a-select
                show-search
                :default-value="form.storeId"
                :key="form.storeId"
                placeholder="请选择驿站"
                style="width:250px"
                option-filter-prop="children"
                @change="handleChangeStage"
              >
              <a-select-option v-for="(item, index) in stageList" :key="index" :value="item.code">
                {{ item.name }}
              </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="合作周期" prop="contractLife">
          <a-input v-model="form.contractLife" placeholder="请输入" style="width:250px"/>
        </a-form-model-item>
      </a-form-model>
  </a-modal>
</template>

<script>
export default {
  inject: ['appRefs'],
  data () {
    const validCode = (rule, value, callback) => {
      if (value) {
        const re = /^[0-9]*[1-9][0-9]*$/
        const rsCheck = re.test(value)
        if (!rsCheck || value.length < 1 || value.length > 19) {
          return callback(new Error('请输入1-19位的数字'))
        }
      }
      callback()
    }
    const validContractLife = (rule, value, callback) => {
      if (this.form.storeId && !value) {
        return callback(new Error('请输入合作周期'))
      }
      callback()
    }
    const validContractType = (rule, value, callback) => {
      if (this.form.storeId && !value) {
        return callback(new Error('请选择签约类型'))
      }
      callback()
    }
    return {
      isShow: false,
      isDetermineLoading: false,
      contractTypeList: [],
      stageList: [],
      form: {},
      formRules: {
        code: [
          { required: true, message: '请输入机柜编号', trigger: 'change' },
          { validator: validCode, trigger: 'change' }
        ],
        modelNo: [{ required: true, message: '请输入设备型号', trigger: 'change' }],
        contractTypeCode: [{ validator: validContractType, trigger: 'change' }],
        contractLife: [{ validator: validContractLife, trigger: 'change' }]
      }
    }
  },
  created () {
    this.getStageList()
    this.getContractTypeList()
  },
  methods: {
    // 设置表单
    setForm (form = {}) {
      this.form = {
        id: form.id,
        name: form.name,
        code: form.code,
        modelNo: form.modelNo,
        deviceTypeCode: form.deviceTypeCode,
        deviceTypeName: form.deviceTypeName,
        contractTypeCode: form.contractTypeCode,
        contractTypeName: form.contractTypeName,
        storeId: form.storeId,
        storeName: form.storeName,
        storeCode: form.storeCode,
        contractLife: form.contractLife
      }
    },
    // 签约类型
    async getContractTypeList () {
      const res = await this.$store.dispatch('http', {
        api: 'getDictItems',
        query: {
          code: 'contractType'
        }
      })
      if (res) {
        const that = this
        res.map(item => {
          that.contractTypeList.push({ code: item.code, name: item.name })
        })
      }
    },
    // 驿站列表
    async getStageList (val) {
      const res = await this.$store.dispatch('http', {
        api: 'getStageList',
        params: {
          name: val
        }
      })
      if (res) {
        const that = this
        res.map(item => {
          that.stageList.push({ code: item.id, name: item.name, idCode: item.code })
        })
      }
    },
    handleChangeStage (e) {
      console.log(e)
      this.form.storeId = e
    },
    // 关闭
    close () {
      this.setForm()
      this.$refs.form.resetFields()
    },
    // 确定
    async determine (e) {
      this.$refs.form.validate(async valid => {
        if (valid) {
          this.contractTypeList.map(item => {
            if (item.code === this.form.contractTypeCode) {
              this.form.contractTypeName = item.name
            }
          })
          this.stageList.map(item => {
            if (item.code === this.form.storeId) {
              this.form.storeName = item.name
              this.form.storeCode = item.idCode
            }
          })
          this.isDetermineLoading = true
          const params = this.$utils.clone(this.form)
          let query = {}
          let api = 'addDevice'
          if (params.id) {
            api = 'modifyDevice'
            query = {
              id: this.form.id
            }
          }
          await this.$store.dispatch('http', {
            api,
            params,
            query,
            complete: () => {
              this.isDetermineLoading = false
            }
          })
          this.$store.dispatch('showToast', { message: (params.id ? '编辑' : '新增') + '机柜成功~' })
          this.isShow = false
          this.close()
          this.$emit('save')
        }
      })
    }
  }
}
</script>

<style lang="scss" module>
.container {
  .form__ct {
    padding: 16px 0 10px;
  }
}
</style>
