<template>
  <div :class="$style.container">
    <!-- 面包屑导航 -->
    <Breadcrumb :breadList="breadList">
       <a-button type="primary" @click="$refs.dialog.setForm();$refs.dialog.isShow = true">新建</a-button>
    </Breadcrumb>
    <a-form ref="form" class="al-form" layout="inline" :model="form" :label-col="{ style: 'width: 80px' }" :wrapper-col="{ style: 'width: 170px' }">
      <a-form-item>
        <a-input v-model="form.storeName" placeholder="驿站名称" />
      </a-form-item>
      <a-form-item>
        <a-input v-model="form.code" placeholder="编号" />
      </a-form-item>
      <a-form-item>
        <a-select v-model="form.status" placeholder="请选择" style="width:180px;">
          <a-select-option :value=0>未生效</a-select-option>
          <a-select-option :value=1>已生效</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item>
        <a-button type="primary" :loading="isQueryLoading" @click="queryList">查询</a-button>
        <a-button @click="resetForm" style="margin-left:20px;">重置</a-button>
      </a-form-item>
    </a-form>
    <a-table :data-source="list"
      :rowKey="record => record.id"
      :columns="columns"
      :pagination="pagination"
      bordered
    >
      <div slot="action1" slot-scope="scope">
        <div>{{ scope.code }}</div>
        <div><span style="color:#4f9efa;">{{ scope | formatStatus }}</span></div>
      </div>
      <div slot="action2" slot-scope="scope">
        <div>{{ scope.storeName }}</div>
        <div>{{ scope.storeId }}</div>
      </div>
      <div slot="action3" slot-scope="scope">
        <div>创建时间：{{ scope.gmtCreate }}</div>
        <div v-if="scope.status === 1">生效时间：{{ scope.gmtEffect }}</div>
      </div>
      <span slot="action" slot-scope="scope">
         <div style="align:center;" v-if="scope.status===0">
          <a @click="$refs.dialog.setForm(scope);$refs.dialog.isShow = true">编辑</a>
          <a-divider type="vertical"/>
          <a @click="$refs.dialog2.setForm(scope);$refs.dialog2.isShow = true">分配驿站</a>
          <a-divider type="vertical"/>
          <a-popconfirm
            title="确定要删除吗？"
            ok-text="是"
            cancel-text="否"
            @confirm="confirm(scope)"
            @cancel="cancel"
          >
            <a href="#">删除</a>
          </a-popconfirm>
        </div>
        <div style="align:center;" v-if="scope.status===1">
          <a-popconfirm
                    title="确定要取消分配驿站吗？"
                    ok-text="是"
                    cancel-text="否"
                    @confirm="cancelConfirm(scope)"
                    @cancel="cancel"
                  >
            <a href="#">取消分配驿站</a>
          </a-popconfirm>
        </div>
      </span>
    </a-table>
    <AddDevice ref="dialog" @save="reloadList"></AddDevice>
    <StageAssign ref="dialog2" @save="reloadList"></StageAssign>
  </div>
</template>

<script>
import Breadcrumb from '@/components/breadcrumb.vue'
import AddDevice from './dialog/addDevice.vue'
import StageAssign from './dialog/stageAssign.vue'
export default {
  components: {
    Breadcrumb,
    AddDevice,
    StageAssign
  },
  data () {
    return {
      breadList: [
        {
          name: '机柜管理',
          url: ''
        }
      ],
      isQueryLoading: false,
      form: {
        storeId: '',
        storeName: '',
        code: '',
        status: ''
      },
      stage: {},
      columns: [
        { title: '编号', width: 150, key: '', scopedSlots: { customRender: 'action1' } },
        { title: '设备型号', width: 150, key: 'modelNo', dataIndex: 'modelNo' },
        { title: '签约类型', width: 100, key: 'contractTypeName', dataIndex: 'contractTypeName' },
        { title: '所在驿站', width: 200, key: '', scopedSlots: { customRender: 'action2' } },
        { title: '合作周期', width: 100, key: 'contractLife', dataIndex: 'contractLife' },
        { title: '时间', width: 250, key: '', scopedSlots: { customRender: 'action3' } },
        { title: '操作', key: 'operation', width: 250, scopedSlots: { customRender: 'action' } }
      ],
      pagination: {
        total: 0,
        current: 1,
        pageSize: this.$enums.Pagination.pageSize,
        showLessItems: false,
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: this.$enums.Pagination.pageSizes,
        showTotal: (total, range) => `共 ${total} 条记录 第 ${range[0]}-${range[1]} 条数据`,
        onChange: pageNo => this.pageNoChange(pageNo),
        onShowSizeChange: (pageNo, pageSize) => this.pageSizeChange(pageNo, pageSize)
      },
      total: 0,
      list: []
    }
  },
  created () {
    this.stage = {
      storeId: this.$route.params.storeId,
      storeName: this.$route.params.storeName
    }
    this.form.storeId = this.stage.storeId
    this.form.storeName = this.stage.storeName
    this.reloadList()
  },
  filters: {
    formatStatus (v) {
      if (v.status === 0) {
        return '未生效'
      } else if (v.status === 1) {
        return '已生效'
      }
    }
  },
  methods: {
    // 重置表单
    resetForm (formName) {
      this.form.code = ''
      this.form.storeId = ''
      this.form.storeName = ''
      this.form.status = ''
      this.queryList()
    },
    // 查询列表
    queryList () {
      this.pagination.current = 1
      this.isQueryLoading = true
      this.reloadList(() => {
        this.isQueryLoading = false
      })
    },
    // 重新加载列表（参数初始化）
    reloadList (callback = () => {}) {
      this.loadList(callback)
    },
    // 加载列表
    async loadList (callback = () => {}) {
      this.isTableLoading = true
      const params = Object.assign({
        pageNo: this.pagination.current,
        pageSize: this.pagination.pageSize
      }, this.form)
      const { total, records } = await this.$store.dispatch('http', {
        api: 'querySaleDevice',
        params,
        complete: () => {
          this.isTableLoading = false
          callback()
        }
      })
      this.pagination.total = total
      if (records) {
        records.forEach(item => {
          item.isLoading = false
        })
      }
      this.list = records
    },
    // 页码切换
    pageNoChange (pageNo) {
      this.pagination.current = pageNo
      this.loadList()
    },
    // 每页显示条数切换
    pageSizeChange (pageNo, pageSize) {
      this.pagination.pageSize = pageSize
      this.pagination.current = pageNo
      this.reloadList()
    },
    cancel () {},
    // 删除驿站
    confirm (device) {
      this.del(device)
    },
    async del (device) {
      device.isLoading = true
      const res = await this.$store.dispatch('http', {
        api: 'deleteMeDevice',
        query: {
          id: device.id
        }
      })
      if (res) {
        device.isLoading = false
        this.$store.dispatch('showToast', { message: '删除成功~' })
        this.queryList()
      }
    },
    // 取消分配驿站
    cancelConfirm (device) {
      this.cancelStage(device)
    },
    // 取消分配驿站
    async cancelStage (device) {
      // device.isLoading = true
      const res = await this.$store.dispatch('http', {
        api: 'cancelStage',
        query: {
          id: device.id
        }
      })
      if (res) {
        // device.isLoading = false
        this.$store.dispatch('showToast', { message: '操作成功~' })
        this.reloadList()
      }
    }
  }
}
</script>

<style lang="scss" module>
.container {
  .form__ct {
    padding: 10px 0;
  }
  .form__items {
    padding-top: 6px;
  }
}
</style>
